// src/store/leadsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie';
import { set } from 'lodash';

let cancelTokenSource;

export const fetchLeadHistory = createAsyncThunk(
  'leads/fetchLeadHistory',
  async (leadId, { rejectWithValue }) => {
    try {
      const token = Cookies.get('auth-token');
      if (!token) throw new Error('No token found');

      const response = await axios.get(`/api/main/lead-history/${leadId}`, {
        headers: { 'x-access-token': token }
      });

      return { leadId, statusHistories: response.data.statusHistories };
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


export const fetchLeads = createAsyncThunk(
  'leads/fetchLeads',
  async (params, thunkAPI) => {
    try {
      if (cancelTokenSource) {
        cancelTokenSource.cancel('Operation canceled due to new request.');
      }
      cancelTokenSource = axios.CancelToken.source();

      const token = Cookies.get('auth-token'); // Читаем токен из куков
      if (!token) {
        throw new Error('No token found');
      }
      const { page, limit, search, userId, country, partner, dateFrom, dateTo, 
        postbackStatus
       } = params
      console.log('Отправляем запрос с параметрами:', { postbackStatus });
      const response = await axios.get('/api/main/get/leads', {
        params: {
          page,
          limit,
          search,
          userId,
          country,
          partner,
          dateFrom,
          dateTo,
          postbackStatus
        },
        headers: {
          'x-access-token': token
        },
        cancelToken: cancelTokenSource.token
      });

      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      }
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

//
// 2. Начальное состояние
//
const initialState = {
  leads: [],
  total: 0,       // сколько всего строк на сервере под фильтры
  totalPages: 0,  // при желании можно также хранить
  page: 1,
  limit: 50,

  // Фильтры – если хотим хранить в Redux:
  search: '',
  userId: '',
  country: '',
  partner: '',
  dateFrom: '',
  dateTo: '',
  postbackStatus: '', // enum: '','0','1', '2',

  loading: false,
  error: null,

  historyLoading: false,
  historyError: null
};

//
// 3. Создаём сам slice
//
const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    // Если хотите отдельные экшены для смены page, limit, и т. д.:
    // Фильтрация по дате
    setDateFrom(state, action) {
      state.dateFrom = action.payload;
    },
    setDateTo(state, action) {
      state.dateTo = action.payload
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setLimit(state, action) {
      state.limit = action.payload;
    },
    setSearch(state, action) {
      state.search = action.payload;
    },
    setUserId(state, action) {
      state.userId = action.payload;
    },
    setCountry(state, action) {
      state.country = action.payload;
    },
    setPartner(state, action) {
      state.partner = action.payload;
    },
    setPostbackStatus(state, action) {
      state.postbackStatus = action.payload;
    },
    updateLead(state, action) {
      const updatedLead = action.payload;
      const index = state.leads.findIndex(lead => lead.id === updatedLead.id);
      if (index !== -1) {
        state.leads[index] = { ...state.leads[index], ...updatedLead };
      }
    },
    removeLead(state, action) {
      const id = action.payload;
      state.leads = state.leads.filter(lead => lead.id !== id);
    }

    // ... и так далее
  },
  extraReducers: builder => {
    // При запуске fetchLeads
    builder.addCase(fetchLeads.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    // При успешном завершении fetchLeads
    builder.addCase(fetchLeads.fulfilled, (state, action) => {
      state.loading = false;
      const { leads, total, totalPages, currentPage } = action.payload;

      // Кладём данные
      state.leads = leads;
      state.total = total;
      state.totalPages = totalPages;
      // Если сервер отдаёт currentPage, можно использовать его:
      if (currentPage !== undefined) {
        state.page = currentPage;
      }
    });
    // При ошибке
    builder.addCase(fetchLeads.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || 'Failed to fetch leads';
    });

    builder.addCase(fetchLeadHistory.fulfilled, (state, action) => {
      const { leadId, statusHistories } = action.payload;
      state.historyLoading = false;
      const index = state.leads.findIndex(l => l.id === leadId);
      if (index !== -1) {
        // Меняем массив `statusHistories` для этого лида
        state.leads[index].statusHistories = statusHistories;
      }
    });
    builder.addCase(fetchLeadHistory.pending, (state) => {
      state.historyLoading = true;
      state.historyError = null;
    });

    builder.addCase(fetchLeadHistory.rejected, (state, action) => {
      state.historyLoading = false;
      state.historyError = action.payload || 'Failed to fetch lead history';
    });

  }
});

// Экспортируем экшены
export const {
  setPage,
  setLimit,
  setSearch,
  setUserId,
  setCountry,
  setPartner,
  setPostbackStatus,
  updateLead,
  removeLead,
  setDateFrom,
  setDateTo
} = leadsSlice.actions;

export default leadsSlice.reducer;
