import React, {
    useEffect,
    useState,
    useCallback
} from "react";
import { Link, useLocation } from "react-router-dom";
import { useHttp } from "../../hooks/http.hook";
import TableWrap from "../components/Table/TabelWrap";
import SimpleModal from '../components/SimpleModal/SimpleModal'
import tableCss from '../components/Table/style.module.scss'
import { flatten } from 'flat'
import { Stack, Box, Grid, Modal, NativeSelect, InputBase, Paper, TablePagination, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles';
import useAuth from "../../hooks/useAuth.hook";
import Preloader from '../components/Preloader'
import FontSizeSlider from "../components/FontSlider/FontSlider";
import NumberDetails from "../components/NumberDetails/NumberDetails";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import debounce from 'lodash.debounce';
import TableOverlayLoader from './TableOverlayLoader';
import s from './s.module.scss'
import UrlTooltip from "../components/UrlTooltip";
import LeadRow from "./LeadRow";
import {
    fetchLeads,
    setPage,
    setLimit,
    setSearch,
    setUserId,
    setCountry,
    setPartner,
    updateLead,
    setPostbackStatus,
    removeLead,
    setDateFrom,
    setDateTo
} from '../../store/leadsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { da } from "date-fns/locale";
import UkrainianDatePicker from "./UkrainianDatePicker";
import UkrainianDateFilters from "./UkrainianDatePicker";
import DateRangeFilter from "./UkrainianDatePicker";
import ExportButton from "./ExportButton";
import { get } from "lodash";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const detailsHandler = (lead) => {
    const partners = {
        'axela': 'message-warning',
        'cpabandtracker': 'message-info',
        'aivix': 'message-danger',
        'cryp.im': 'message-attantion',
        'trump': 'message-dark'

    }
    return partners[lead] || ''
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Leads = () => {

    const dispatch = useDispatch();

    // Достаём поля из redux
    const {
        leads,
        total,
        page,
        limit,
        search,
        userId,
        country,
        partner,
        postbackStatus,
        dateFrom,
        dateTo,
        loading,
        error
    } = useSelector(state => state.leads);

    const [fontSize, setFontSize] = React.useState(parseInt(localStorage.getItem('tableFontSize')) || 14);
    const [selectedIds, setSelectedIds] = React.useState([]);
    const [openOptions, setOpenOptions] = React.useState(false);
    const [partners, setPartners] = React.useState([])
    const auth = useAuth()
    const { request } = useHttp()
    const [modal, setModal] = React.useState({
        modal: false,
        confirm: false
    })
    const [users, setUsers] = React.useState([])
    const [countries, setCountries] = React.useState([])
    const [details, setDetails] = React.useState(null)

    const [postbackPrice, setPostbackPrice] = React.useState(null);
    // Resend
    const [from, setFrom] = React.useState('00:00')
    const [to, setTo] = React.useState('23:59')
    const [changePartner, setChangePartner] = React.useState()
    const [postbackType, setPostbackType] = React.useState('')

    // Локальный стейт для поля ввода
    const [searchTerm, setSearchTerm] = useState(search);

    const getLeads = async (e) => {
        dispatch(fetchLeads({ page, limit, search, userId, country, partner, postbackStatus, dateFrom, dateTo }));
    }

    useEffect(() => {
        getLeads();
    }, [dispatch, page, limit, search, userId, country, partner, dateFrom, dateTo, postbackStatus]);

    const handleChangePage = (_, newPageIndex) => {
        dispatch(setPage(newPageIndex + 1));
    };

    const handleChangeRowsPerPage = event => {
        dispatch(setLimit(parseInt(event.target.value, 10)));
        dispatch(setPage(1));
    };



    const startTableRef = React.useRef(null);

    const handleOpenResend = () => {
        setOpenOptions(true);
    }

    const handleCloseResend = () => {
        setOpenOptions(false);
    }

    const getConversionList = async () => {
        await request(`/api/side/conversion/get`, 'POST')
        getLeads()
    }

    const getUsersList = async () => {
        const res = await request(`/api/main/users`)
        setUsers(res.users)
    }

    const getCountries = async () => {
        const res = await request(`/api/main/get/country`)
        setCountries(res.country)
    }

    const getPartners = async (e) => {
        const res = await request(`/api/main/get-partners`, 'GET', null, {
            ['x-access-token']: auth.token
        })
        const activePartners = res.partners.filter(partner => partner.is_active)
        setPartners(activePartners)
        setChangePartner(activePartners[0].endpoint_name)
    }

    const handleScrollToTableStart = () => {
        startTableRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const debouncedSetSearch = useCallback(
        debounce((value) => {
            dispatch(setSearch(value));
            dispatch(setPage(1));
        }, 500),
        [dispatch]
    );

    useEffect(() => {
        debouncedSetSearch(searchTerm);
        return () => {
            debouncedSetSearch.cancel();
        };
    }, [searchTerm, debouncedSetSearch]);

    React.useEffect(() => {
        getUsersList()
        getCountries()
        getPartners()
    }, [])

    const headers = {
        '': '',
        'id': '',
        ['time']: '',
        ['first name']: '',
        ['last name']: '',
        email: '',
        phone: '',
        ['callcenter status']: '',
        ref: '',
        status: '',
        ip: '',
        ['link name']: '',
        gone: '',
        ['details']: '',
        '': '',
    }



    const handleDelete = async (id) => {
        try {
            await request(`/api/main/remove/lead/${id}`, 'POSt', {}, { Authorization: `Bearer ${auth.token}` })
            dispatch(removeLead(id));
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        localStorage.setItem('tableFontSize', fontSize);
    }, [fontSize]);

    const handleCheckboxChange = React.useCallback((el) => {
        setSelectedIds(prevSelected => {
            const index = prevSelected.findIndex(obj => obj.id === el.id);
            if (index === -1) {
                return [...prevSelected, el];
            }
            return prevSelected.filter(obj => obj.id !== el.id);
        });
    }, [leads]);

    const resendLeads = async () => {
        try {
            await request(`/api/main/resend/leads`, 'POST', { ids: selectedIds }, { Authorization: `Bearer ${auth.token}` })
            setSelectedIds([]);
            getLeads();
        } catch (error) {
            console.error(error);
        } finally {
            setOpenOptions(false)
        }
    }

    const changeTime = (e) => {
        const value = e.target.value
        const name = e.target.name

        if (name === 'from') {
            setFrom(value)
        }

        if (name === 'to') {
            setTo(value)
        }
    }

    const addResendOptions = () => {
        setSelectedIds(prev =>
            prev.map(el => {
                return {
                    ...el,
                    send_time_from: from,
                    send_time_to: to,
                    action: changePartner,
                };
            })
        );
    };

    const resendDefault = () => {
        setSelectedIds(prev => {
            const newEl = prev.map(el => {
                el.send_time_from = '00:00'
                el.send_time_to = '23:59'
                el.action = partners.filter(partner => partner.is_active)[0].endpoint_name
                return el
            })

            return newEl

        })
    }

    const sendPostback = async () => {
        try {
            const numericPostbackPrice = postbackPrice !== null ? postbackPrice : 0;
            const res = await request(`/api/main/postback`, 'POST', {
                ids: selectedIds,
                type: postbackType,
                price: numericPostbackPrice
            },
                { Authorization: `Bearer ${auth.token}` });

            console.log(res)

            res.leads.map(updatedLead => {
                dispatch(updateLead(updatedLead));
            });
            alert('Postback sent')
        }
        catch (error) {
            alert('Error')
            console.error(error);
        }
        finally {
            setModal({ ...modal, confirm: false });
        }
    }

    const setQuality = (id) => {
        request(`/api/main/leads/set/quality/${id}`, 'GET')
            .then(res => {
                dispatch(updateLead(res.leads));
            })
            .catch(console.error);
    };


    return <>
        <div className="card" ref={startTableRef}>

            <div className="card-header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '16px 24px' }}>
                {/* Левая кнопка "Назад" со стрелкой */}
                <Link to="/" className="btn btnDanger" style={{ display: 'flex', alignItems: 'center' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style={{ width: 24, height: 24 }} className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>
                </Link>

                <h2 style={{ fontSize: '20px', fontWeight: 'bold', margin: 0 }}>Leads</h2>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <ExportButton />
                    <button
                        className={loading ? 'btn btnSecondary' : 'btn btnPrimary'}
                        onClick={getConversionList}
                        disabled={loading ? 'disabled' : ''}
                        style={{ display: 'flex', alignItems: 'center', }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '20px', height: '20px' }} viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
                        </svg>
                    </button>
                </div>
            </div>

            <Paper sx={{ p: '2px 4px', m: 2, display: 'flex', alignItems: 'center' }}>
                <InputBase
                    type="search"
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search By ID, Name, Last Name or Email"
                    onChange={e => setSearchTerm(e.target.value)}
                    value={searchTerm}
                />
            </Paper>
            {!!auth.user?.isAdmin && <Box sx={
                {
                    flexGrow: 1,
                    position: 'sticky',
                    top: 0,
                    zIndex: 2,
                    backgroundColor: '#fff',
                    borderBottom: '1px solid #ccc'
                }
            }>
                <Grid container gap={'10px'}>
                    {!!users.length &&
                        <Item>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                Sort by: <div>
                                    <select onChange={e => {
                                        dispatch(setUserId(e.target.value));
                                        dispatch(setPage(1));
                                    }}
                                        className='btn btnPrimary'>
                                        <option value={''} >All</option>
                                        {users.map(user => <option key={user.id} value={user.id}>{user.email}</option>)}
                                    </select>
                                </div>
                            </div>
                        </Item>
                    }
                    {!!countries.length &&
                        <Item>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                Country: <div>
                                    <select onChange={
                                        e => {
                                            dispatch(setCountry(e.target.value));
                                            dispatch(setPage(1));
                                        }
                                    } className='btn btnPrimary'>
                                        <option value={''} >All</option>
                                        {countries.map(country => <option key={country.country} value={country.country}>{country.country}</option>)}
                                    </select>
                                </div>
                            </div>
                        </Item>
                    }

                    {!!auth.user?.isAdmin && !!partners.length &&
                        <Item>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                Partner: <div>
                                    <select onChange={
                                        e => {
                                            dispatch(setPartner(e.target.value));
                                            dispatch(setPage(1));
                                        }
                                    } className='btn btnPrimary'>
                                        <option value={''} >All</option>
                                        {partners.map(partner => <option key={partner.endpoint_name} value={partner.endpoint_name.toLowerCase()}>{partner.name}</option>)}
                                    </select>
                                </div>
                            </div>
                        </Item>
                    }
                    <Item>
                        {/* Date Filter */}
                        <DateRangeFilter />
                    </Item>
                    {/* postbackStatus */}
                    <Item>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', }}>
                            Postback: <div>
                                <select onChange={
                                    e => {
                                        dispatch(setPostbackStatus(e.target.value));
                                        dispatch(setPage(1));
                                    }
                                } className='btn btnPrimary'>
                                    <option value={''} >All</option>
                                    <option value={0}>Not Sent</option>
                                    <option value={1}>Success</option>
                                    <option value={2}>Reject</option>
                                </select>
                            </div>
                        </div>
                    </Item>
                    <Item>
                        <FontSizeSlider fontSize={fontSize} onChange={setFontSize} />
                    </Item>
                    <Item>
                        <button className={`btn ${selectedIds.length != 0 ? 'btnPrimary' : 'btnSecondary'}`} onClick={selectedIds.length != 0 && handleOpenResend}>Resend</button>
                    </Item>
                    <Item sx={{ gap: 2, display: 'flex' }}>
                        <button className={`btn ${selectedIds.length != 0 ? 'btnSuccess' : 'btnSecondary'}`} onClick={
                            () => {
                                if (selectedIds.length <= 0) return;
                                setModal({ ...modal, confirm: true });
                                setPostbackType('success');
                            }
                        }>Success postback</button>
                        <button className={`btn ${selectedIds.length != 0 ? 'btnDanger' : 'btnSecondary'}`} onClick={
                            () => {
                                if (selectedIds.length <= 0) return;
                                setModal({ ...modal, confirm: true });
                                setPostbackType('reject');
                            }
                        }>Reject postback</button>
                    </Item>
                </Grid>
            </Box>}
            <TableOverlayLoader loading={loading}>
                {leads.length > 0 ? <>
                    {/* selectedIds counts */}
                    <Grid container justifyContent={'flex-end'} sx={{ flexWrap: 'nowrap', mt: 2 }}>
                        <div style={{ width: 100 }}><b>Selected Leads:</b> {selectedIds.length}</div>

                        <TablePagination
                            sx={{ width: "100%" }}
                            rowsPerPageOptions={[50, 100, 150, 300, 400, { label: 'All', value: -1 }]}
                            colSpan={3}
                            count={total}
                            rowsPerPage={limit}
                            page={page - 1}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                    <TableWrap>
                        <div className={tableCss.table} style={{ fontSize }}>
                            <div className={tableCss.header} style={{ "gridTemplateColumns": `25px 40px ${70 + fontSize}px repeat(9,minmax(80px, 1fr)) ${90 + fontSize}px ${90 + fontSize}px 25px` }}>
                                {Object.keys(headers).map(header => <div key={header} className={tableCss.cell}>
                                    {header}
                                </div>)}
                            </div>
                            <div className={tableCss.content}>
                                {leads.map((lead, index) => (
                                    <LeadRow
                                        key={lead.id}
                                        lead={lead}
                                        index={index}
                                        page={page}
                                        rowsPerPage={limit}
                                        fontSize={fontSize}
                                        handleDelete={() => handleDelete(lead.id)}
                                        setQuality={() => setQuality(lead.id)}
                                        setModal={setModal}
                                        modal={modal}
                                        setDetails={setDetails}
                                        isChecked={selectedIds.some(obj => obj.id === lead.id)}
                                        handleCheckboxChange={(e) => handleCheckboxChange(lead)}
                                        auth={auth}
                                        detailsHandler={detailsHandler}
                                        headers={headers}
                                    />
                                ))}
                            </div>
                        </div>
                    </TableWrap>
                    <TablePagination
                        sx={{ width: "100%" }}
                        rowsPerPageOptions={[50, 100, 150, 300, 400, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={total}
                        rowsPerPage={limit}
                        page={page - 1}
                        SelectProps={{
                            inputProps: {
                                'aria-label': 'rows per page',
                            },
                            native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </> : <div className="text-center"
                    style={{ padding: '20px', fontSize: '22px', fontWeight: 'bold' }}>
                    No leads
                </div>}
            </TableOverlayLoader>

        </div>

        {modal.modal && details && <SimpleModal modalName={'modal'} closeSetter={setModal}>
            <div>Respons Details</div>
            {console.log(details)}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <pre style={{
                    textAlign: 'left',
                    textWrap: 'wrap',
                    background: '#fff'
                }}>
                    <code>
                        {JSON.stringify(details, null, 2)}
                    </code>
                </pre>
            </div>
        </SimpleModal>}

        {/* Confirm postback */}
        {modal.confirm && postbackType != '' && <SimpleModal modalName={'confirm'} closeSetter={setModal}>
            <div style={{
                marginTop: 'auto',
            }}>
                {postbackType == 'success' ? 'Are you sure you want to send success postback?' : 'Are you sure you want to send reject postback?'}
            </div>
            {postbackType == 'success' ? <input
                onChange={(e) => {
                    const value = e.target.value;
                    setPostbackPrice(value === '' ? null : Number(value));
                }}
                value={postbackPrice === null ? '' : postbackPrice}
                type="number"
                placeholder="Price"
                style={{ marginBottom: 26 }} /> : null}
            <div style={{ display: 'flex', gap: '10px' }}>
                <div className='btn btnDanger' onClick={setModal}>Cancel</div>
                <div className='btn btnSuccess' onClick={sendPostback}>Send</div>
            </div>
        </SimpleModal>}
        {/* Resend */}
        {!!auth.user?.isAdmin &&
            <Modal
                keepMounted
                className={s.sheduleModal}
                open={openOptions}
                onClose={handleCloseResend}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >

                <Stack sx={style} spacing={1} >
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseResend}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {!!partners.length && changePartner && <Paper>
                        <div className={s.timeWrapForAll}>
                            From <input type={'time'} name='from' value={from} onChange={changeTime} />
                            to <input onChange={changeTime} name='to' min={from} type={'time'} value={to} />

                            transfer to → <NativeSelect
                                defaultValue={partners.filter(partner => partner.is_active)[0].endpoint_name}
                                inputProps={{
                                    name: 'age',
                                    id: 'uncontrolled-native',
                                }}
                                onChange={e => setChangePartner(e.target.value)}
                            >
                                {partners
                                    .filter(partner => partner.is_active)
                                    .map(partner => <option key={partner.endpoint_name} value={partner.endpoint_name}>{partner.name}</option>)}
                            </NativeSelect>

                            <div className="btn btnSuccess" onClick={addResendOptions}>Set All</div>
                            <div className="btn btnDanger" title="Set Default Value For All" onClick={resendDefault}><DeleteForeverIcon /></div>

                        </div>
                    </Paper>}

                    <div className={s.transferModal}>
                        {selectedIds.map((el) => <HolderItem key={el.id} link={el} partners={partners.filter(partner => partner.is_active)} setSelectedIds={setSelectedIds} />)}
                    </div>
                    <div className={`btn ${loading ? 'btnSecondary' : 'btnSuccess'}`} onClick={loading ? null : resendLeads}>Save</div>

                </Stack>
            </Modal>
        }
    </>
}

const HolderItem = ({ link, partners, setSelectedIds }) => {
    const changeTime = (e) => {
        const { name, value } = e.target;

        setSelectedIds(prev =>
            prev.map(el => {
                if (el.id === link.id) {
                    // Повертаємо новий об’єкт, не мутуємо оригінал
                    const updated = { ...el };
                    if (name === 'from') updated.send_time_from = value;
                    if (name === 'to') updated.send_time_to = value;
                    return updated;
                }
                return el;
            })
        );
    };

    const setChangePartner = (e) => {
        const value = e.target.value;

        setSelectedIds(prev =>
            prev.map(el => {
                if (el.id === link.id) {
                    return {
                        ...el,
                        action: value,
                    };
                }
                return el;
            })
        );
    };

    const removeHolder = (linkId, id) => {
        setSelectedIds(prev => {
            const newEl = prev.map(el => {
                el.send_time_from = '00:00'
                el.send_time_to = '23:59'
                return el
            })

            return newEl

        })
    }

    return <Paper>
        <div className={s.transfer_card}>

            <div className={s.header}>
                <div>{link.first_name}</div>
                <div>{link.last_name}</div>
                <div>{link.email}</div>
                <div><NumberDetails phoneNumber={link['phone']} /></div>
                <div>{link.Link.offer_name}</div>
                <div className={`message ${detailsHandler(link.gone || flatten(link?.other_params)?.partner || '')}`} style={{ margin: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title={`${link.gone || (flatten(link?.other_params)?.partner ?? '')}`}>{link.gone || (flatten(link?.other_params)?.partner ?? '')}</div>
            </div>
            <div className={s.timeWrap}>
                From <input type={'time'} name='from' value={link.send_time_from || '00:00'} onChange={changeTime} />
                to <input onChange={changeTime} min={link.send_time_from || '00:00'} name='to' type={'time'} value={link.send_time_to || '23:59'} />
                transfer to → <NativeSelect
                    defaultValue={partners
                        .filter(partner => partner.is_active)
                    [0].endpoint_name}
                    value={link.action}
                    inputProps={{
                        name: 'age',
                        id: 'uncontrolled-native',
                    }}
                    onChange={setChangePartner}
                >
                    {partners
                        .filter(partner => partner.is_active)
                        .map(partner => <option value={partner.endpoint_name}>{partner.name}</option>)}
                </NativeSelect>
            </div>

            <div className={s.shedule}>
                {/* <Stack sx={{ width: '100%' }} spacing={1}>
                {link.Holders && link.Holders.map((el, i) => <Alert severity="warning" className="shedule-alert">
                    From <b>{el.holder_from}</b> to <b>{el.holder_to}</b>
                    <div className='close' onClick={() => {
                        removeHolder(link.id, el.id)
                    }}><CloseIcon /></div>
                </Alert>)}
            </Stack> */}
            </div>

        </div>
    </Paper>
}

export default Leads