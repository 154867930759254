import React from 'react';
import styles from './TableOverlayLoader.module.css';

export default function TableOverlayLoader({ loading, children }) {
    return (
        <div className={styles.container}>
            {children}

            {loading && (
                <div className={styles.overlay}>
                    <div className={styles.sticky}>
                        <div className={styles.spinner}></div>
                        <div className={styles.text}>Loading...</div>
                    </div>
                </div>
            )}
        </div>
    );
}
