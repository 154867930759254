import React from "react"
import { useCallback, useState, useRef, useEffect } from "react"
import useAuth from "./useAuth.hook"


export const useHttp = () => {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const auth = useAuth()

    // Store active AbortController instances
    const abortControllersRef = useRef(new Map())

    const request = useCallback(async (url, method = 'GET', body = null, headers = {}, requestId = Date.now().toString()) => {
        setLoading(true)

        // Create a new AbortController for this request
        const controller = new AbortController()
        abortControllersRef.current.set(requestId, controller)

        try {
            if (body) {
                body = JSON.stringify(body)
                headers['Content-Type'] = 'application/json'
            }
            if (!!auth.token) {
                headers['x-access-token'] = auth.token
            }

            const respons = await fetch(url, { method, body, headers, signal: controller.signal })
            const data = await respons.json()

            if (!respons.ok)
                throw new Error(data.message || 'Error')

            setLoading(false)
            abortControllersRef.current.delete(requestId)
            return data
        } catch (e) {
            setLoading(false)
            setError(e.message)
            abortControllersRef.current.delete(requestId)
            throw e
        }
    }, [])


    // Function to abort a specific request
    const abortRequest = useCallback((requestId) => {
        const controller = abortControllersRef.current.get(requestId)
        if (controller) {
            controller.abort()
            abortControllersRef.current.delete(requestId)
        }
    }, [])

    // Function to abort all pending requests
    const abortAll = useCallback(() => {
        abortControllersRef.current.forEach(controller => {
            controller.abort()
        })
        abortControllersRef.current.clear()
    }, [])

    const clearError = () => setError(null)
    return {
        loading, request, error, clearError, abortRequest, abortAll
    }
}