import React, { useCallback } from "react";
import { useHttp } from "../../../hooks/http.hook";

const RandomHolderToggle = () => {
    const { request, loading } = useHttp();
    const [status, setStatus] = React.useState(false);

    const getStatus = useCallback(async () => {
        try {
            const data = await request(`/api/utils/random-holder`, 'GET');
            if (data.success === true) {
                setStatus(data.filterEnabled);
            }
            console.log(data);
        } catch (e) {
            console.log(e);
        }
    }, [request]);

    const setStatusIsActive = useCallback(async (newStatus) => {
        try {
            const data = await request(`/api/utils/random-holder-toggle`, 'POST', {
                enabled: newStatus
            });
            if (data.success === true) {
                setStatus(newStatus);
            }
            console.log(data);
        } catch (e) {
            console.log(e);
        }
    }, [request]);

    React.useEffect(() => {
        getStatus();
    }, [getStatus]);

    return (
        <div style={{ display: "flex", alignItems: "center", marginLeft: "20px" }} title="Відправка лідів у випадковому порядку">
            <button
                onClick={() => setStatusIsActive(!status)}
                style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
                aria-label="Toggle Holder"
            >

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke={status ? "green" : "red"} className="size-6"
                    style={{
                        width: "54px",
                        height: "54px",
                        filter: `drop-shadow(0 0 3px ${status ? "green" : "red"})`
                    }}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 0 0-3.7-3.7 48.678 48.678 0 0 0-7.324 0 4.006 4.006 0 0 0-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 0 0 3.7 3.7 48.656 48.656 0 0 0 7.324 0 4.006 4.006 0 0 0 3.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3-3 3" />
                </svg>
                <span>
                    {loading ? "Loading..." : status ? "Enabled" : "Disabled"}
                </span>
            </button>
        </div>
    );
};

export default RandomHolderToggle;
