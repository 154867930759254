import React from "react";
import { useHttp } from "../../hooks/http.hook";
import TableWrap from "../components/Table/TabelWrap";
import tableCss from '../components/Table/style.module.scss'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import useAuth from "../../hooks/useAuth.hook";
import { Checkbox, InputBase } from "@mui/material";
import Preloader from '../components/Preloader'
import LeadItem from "./LeadItem";
import { toast } from "react-toastify";
import HolderToggle from "../components/HolderToggle/HolderToggle";
import RandomHolderToggle from "../components/RandomHolderToggle/RandomHolderToggle";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const Holder = () => {
    const auth = useAuth()
    const { request, loading } = useHttp();
    const { request: sendLeadReq, loading: sendLeadLoad } = useHttp();
    const { request: deleteLeadReq, loading: deleteLeadLoad } = useHttp();
    const { request: bulkDeleteLeadReq, loading: bulkDeleteLeadLoad } = useHttp();
    const { request: bulkRemoveLeadReq, loading: bulkRemoveLeadLoad } = useHttp();

    const [selectedIds, setSelectedIds] = React.useState([]);

    const [leads, setLeads] = React.useState([])
    const [nameFilter, setNameFilter] = React.useState('')

    const headers = ['', '', '', 'Created At', 'First Name', 'Last Name', 'Email', 'Phone', 'Ref', 'Link', '']

    const getLeads = async (e) => {
        const res = await request(`/api/main/holder/leads`)
        setLeads(res)
    }

    React.useEffect(() => {
        getLeads()
    }, [])

    const nameFilterHandler = (event) => {
        setNameFilter(event.target.value)
    }


    const handleDelete = React.useCallback(async (id) => {
        try {
            await deleteLeadReq(`/api/main/holder/remove/lead/${id}`, 'POST', {}, { Authorization: `Bearer ${auth.token}` });
            setLeads(prevLeads => prevLeads.filter(lead => lead.id !== id));
        } catch (error) {
            console.error(error);
        }
    }, [auth.token, deleteLeadReq]);

    // Функція для зміни вибору (checkbox)
    const handleSelectChange = (id, checked) => {
        setSelectedIds((prev) => {
            if (checked) {
                return [...prev, id];
            } else {
                return prev.filter((item) => item !== id);
            }
        });
    };

    const sendLeadNow = React.useCallback(async (id) => {
        toast.promise(
            sendLeadReq(`/api/main/holder/send/lead/${id}`, 'GET', null, { Authorization: `Bearer ${auth.token}` })
                .then(res => {
                    const { resData } = res;

                    if (!resData) {
                        console.error('Lead sending failed');
                        throw new Error('Lead sending failed', {
                            statusCode: 500,
                            leadId: id
                        })
                    }

                    if (resData.statusCode && resData.statusCode === 200) {
                        console.log("Status: ", resData.statusCode);
                        setLeads(prevLeads => prevLeads.filter(lead => lead.id !== id));
                        return 'Lead sent successfully';
                    } else {
                        console.error('Lead sending failed');
                        throw new Error('Lead sending failed', {
                            // status 500
                            statusCode: 500,
                            leadId: id
                        });
                    }
                }
                ),
            {
                pending: 'Sending lead...',
                success: {
                    render({ data }) {
                        return data;
                    },
                    autoClose: 1000, // Устанавливаем время отображения на 500 мс
                },
                error: {
                    render({ data }) {
                        setLeads(prevLeads => prevLeads.filter(lead => lead.id !== id));
                        return data instanceof Error ? data.message : data;
                    },
                    autoClose: 1000, // Устанавливаем время отображения на 500 мс
                },
            }
        ).catch(error => {
            console.error(error);
        });
    }, [auth.token, sendLeadReq]);

    const handleBulkDelete = React.useCallback(async () => {
        try {
            await bulkDeleteLeadReq(
                `/api/main/holder/delete/lead/bulk`,
                'POST',
                { ids: selectedIds },
                { Authorization: `Bearer ${auth.token}` }
            );
            setLeads(prevLeads => prevLeads.filter(lead => !selectedIds.includes(lead.id)));
            setSelectedIds([]);
            toast.success('Permanent deletion');
        } catch (error) {
            toast.error('Error while removing selected leads');
            console.error(error);

        }
    }, [auth.token, bulkDeleteLeadReq, selectedIds]);

    // Масове переміщення (Remove & Save) – виклик ендпоінту /holder/remove/lead/bulk
    const handleBulkMove = async () => {
        try {
            const res = await bulkRemoveLeadReq(
                `/api/main/holder/remove/lead/bulk`,
                "POST",
                { ids: selectedIds },
                { Authorization: `Bearer ${auth.token}` }
            );
            // Оновлюємо список лідів, видаляючи переміщені записи
            setLeads((prevLeads) =>
                prevLeads.filter((lead) => !selectedIds.includes(lead.id))
            );
            setSelectedIds([]);
            toast.success("Removed & saved");
        } catch (error) {
            console.error("Bulk remove & save error:", error);
            toast.error("Error while removing & saving selected leads");
        }
    };

    // Обчислюємо список відфільтрованих записів для відображення
    const filteredLeads = leads.filter((lead) =>
        (lead.first_name + " " + lead.last_name + " " + lead.email)
            .toLowerCase()
            .includes(nameFilter.toLowerCase())
    );

    return <div className="card" >
        <Box sx={{ p: '2px 4px', m: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h2>Leads in Holder ({
                filteredLeads.length
            })</h2>
            <div style={{ display: "flex", gap: "10px" }}>
                <RandomHolderToggle />
                <HolderToggle />
            </div>
        </Box>
        <Paper sx={{ p: '2px 4px', m: 2, display: 'flex', alignItems: 'center' }}>
            <InputBase
                type="search"
                sx={{ ml: 1, flex: 1 }}
                placeholder="Name, Last Name or Email"
                onChange={nameFilterHandler}
                value={nameFilter}
            />
        </Paper>

        {/* Панель для масових операцій: Remove & Save та Delete */}
        {selectedIds.length > 0 && (
            <Box sx={{ display: "flex", gap: "10px", m: 2 }}>
                <button
                    onClick={handleBulkMove}
                    disabled={selectedIds.length === 0}
                    className="btn btnPrimary"
                >
                    Remove &amp; Save
                </button>
                <button
                    onClick={handleBulkDelete}
                    disabled={selectedIds.length === 0}
                    className="btn btnDanger"
                >
                    Delete
                </button>
            </Box>
        )}

        {loading && <Preloader />}
        {!loading && leads.length === 0 && <h2>No Results</h2>}
        {!loading && leads.length > 0 && <TableWrap>
            <div className={tableCss.table}>
                <div className={tableCss.header} style={{
                    "gridTemplateColumns": "45px 45px 45px repeat(3, 100px) 200px 200px 200px 90px 120px",
                    "alignItems": "center",
                }}>
                    {headers.map((header, idx) => {
                        // Для першого стовпця виводимо чекбокс "Select All"
                        if (idx === 0) {
                            return (
                                <div
                                    key={idx}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={
                                            filteredLeads.length > 0 &&
                                            filteredLeads.every((lead) =>
                                                selectedIds.includes(lead.id)
                                            )
                                        }
                                        indeterminate={
                                            filteredLeads.some((lead) =>
                                                selectedIds.includes(lead.id)
                                            ) &&
                                            !filteredLeads.every((lead) =>
                                                selectedIds.includes(lead.id)
                                            )
                                        }
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                // Додаємо всі id відфільтрованих записів до вибраних
                                                const allFilteredIds = filteredLeads.map(
                                                    (lead) => lead.id
                                                );
                                                setSelectedIds((prev) => [
                                                    ...new Set([...prev, ...allFilteredIds]),
                                                ]);
                                            } else {
                                                // Видаляємо з вибраних всі id, що належать відфільтрованим записам
                                                setSelectedIds((prev) =>
                                                    prev.filter(
                                                        (id) =>
                                                            !filteredLeads.some(
                                                                (lead) => lead.id === id
                                                            )
                                                    )
                                                );
                                            }
                                        }}
                                    />
                                </div>
                            );
                        }
                        return <div key={idx}>{header}</div>;
                    })}

                </div>
                <div className={tableCss.content}>
                    {filteredLeads.map((lead, index) => (
                        <LeadItem
                            key={lead.id + '-' + index}
                            lead={lead}
                            index={index}
                            handleDelete={handleDelete}
                            sendLeadNow={sendLeadNow}
                            selected={selectedIds.includes(lead.id)}
                            onSelectChange={(checked) =>
                                handleSelectChange(lead.id, checked)
                            }
                        />
                    ))}
                </div>
            </div>
        </TableWrap>}
    </div>
}

export default Holder