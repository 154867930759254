import React, { useCallback } from "react";
import { useHttp } from "../../../hooks/http.hook";

const HolderToggle = () => {
    const { request, loading } = useHttp();
    const [status, setStatus] = React.useState(false);

    const getStatus = useCallback(async () => {
        try {
            const data = await request(`/api/utils/holder`, 'GET');
            if (data.success === true) {
                setStatus(data.filterEnabled);
            }
            console.log(data);
        } catch (e) {
            console.log(e);
        }
    }, [request]);

    const setStatusIsActive = useCallback(async (newStatus) => {
        try {
            const data = await request(`/api/utils/holder-toggle`, 'POST', {
                enabled: newStatus
            });
            if (data.success === true) {
                setStatus(newStatus);
            }
            console.log(data);
        } catch (e) {
            console.log(e);
        }
    }, [request]);

    React.useEffect(() => {
        getStatus();
    }, [getStatus]);

    return (
        <div style={{ display: "flex", alignItems: "center", marginLeft: "20px" }} title="Активувати/Деактивувати відправку лідів">
            <button
                onClick={() => setStatusIsActive(!status)}
                style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
                aria-label="Toggle Holder"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke={status ? "green" : "red"}
                    style={{
                        width: "54px",
                        height: "54px",
                        /* Додаємо ефект drop-shadow,
                           який створює відбиток кольору (зелений або червоний) */
                        filter: `drop-shadow(0 0 3px ${status ? "green" : "red"})`
                    }}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5.636 5.636a9 9 0 1 0 12.728 0M12 3v9"
                    />
                </svg>
                <span>
                    {loading ? "Loading..." : status ? "Enabled" : "Disabled"}
                </span>
            </button>
        </div>
    );
};

export default HolderToggle;
