import React, { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { setDateFrom, setDateTo, setPage } from '../../store/leadsSlice';
import { uk } from 'date-fns/locale'; // Импортируем украинскую локализацию
// import { enUS } from 'date-fns/locale'; // Можно также импортировать английскую локализацию

// Регистрируем украинскую локализацию
registerLocale('uk', uk);
// registerLocale('en', enUS); // Для английской локализации

// Устанавливает время начала дня: 00:00:00
const setStartOfDay = (date) => {
  if (!date) return null;
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
};

// Устанавливает время конца дня: 23:59:59
const setEndOfDay = (date) => {
  if (!date) return null;
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
};

// Компонент для кастомного инпута с иконкой календаря
const CustomInput = forwardRef(({ value, onClick, placeholder }, ref) => (
  <div style={{ position: 'relative', width: '100%' }}>
    <input
      value={value}
      onClick={onClick}
      ref={ref}
      placeholder={placeholder}
      style={{
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        width: 'auto',
        cursor: 'pointer'
      }}
      readOnly
    />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: 'absolute',
        right: '8px',
        top: '50%',
        transform: 'translateY(-50%)',
        width: '16px',
        height: '16px',
        color: '#666',
        pointerEvents: 'none'
      }}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
        clipRule="evenodd"
      />
    </svg>
  </div>
));

const DateRangeFilter = () => {
  const dispatch = useDispatch();

  // Получаем текущие значения dateFrom и dateTo из Redux store
  const { dateFrom, dateTo } = useSelector(state => state.leads);

  // Обработчик изменения начальной даты
  const handleDateFromChange = (date) => {
    const normalizedFrom = setStartOfDay(date);
    console.log("From: ", normalizedFrom);
    dispatch(setDateFrom(normalizedFrom));
    // Сбрасываем страницу на первую при изменении фильтров
    dispatch(setPage(1));
  };

  // Обработчик изменения конечной даты
  const handleDateToChange = (date) => {
    const normalizedTo = setEndOfDay(date);
    console.log("To: ", normalizedTo);

    dispatch(setDateTo(normalizedTo));
    // Сбрасываем страницу на первую при изменении фильтров
    dispatch(setPage(1));
  };

  // Стили для контейнера
  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
  };

  // Стили для группы инпутов
  const groupStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  };

  // Стили для лейбла
  const labelStyle = {
    fontSize: '14px',
    fontWeight: '500',
    marginBottom: '5px',
    color: '#4b5563'
  };

  return (
    <div style={containerStyle}>
      <div style={groupStyle}>
        <DatePicker
          selected={dateFrom}
          onChange={handleDateFromChange}
          placeholderText="Дата від"
          maxDate={dateTo || undefined}
          dateFormat="dd.MM.yyyy"
          markToday={true}
          isClearable
          locale="uk"
          customInput={<CustomInput />}
        />
      </div>

      <div style={groupStyle}>
        <DatePicker
          selected={dateTo}
          onChange={handleDateToChange}
          placeholderText="Дата до"
          minDate={dateFrom || undefined}
          dateFormat="dd.MM.yyyy"
          markToday={true}
          locale="uk"
          isClearable
          customInput={<CustomInput />}
        />
      </div>
    </div>
  );
};

export default DateRangeFilter;