import React, { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';

const ExportButton = () => {
    const { search, userId, country, partner, dateFrom, dateTo } = useSelector(state => state.leads);
    const [loading, setLoading] = useState(false);

    const exportToCSV = async () => {
        if (loading) return; // предотвратить повторное нажатие, если уже идет загрузка
        setLoading(true);
        try {
            const token = Cookies.get('auth-token');
            const params = { search, userId, country, partner, dateFrom, dateTo };

            const response = await axios.get('/api/main/export/leads', {
                params,
                headers: { 'x-access-token': token },
                responseType: 'blob'
            });

            // Формируем имя файла в формате leads_DD-MM-YYYY.csv
            const fileName = `leads_${new Date().toLocaleDateString('uk-UA').replace(/\//g, '-')}.csv`;

            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error exporting CSV:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Button variant="contained" color="primary" onClick={exportToCSV} disabled={loading} sx={{
            background: 'green',
            ":hover": {
                background: 'darkgreen',
            }
        }}>
            {loading ? (
                <>
                    <CircularProgress size={20} style={{ marginRight: '8px' }} />
                </>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6" style={{
                    width: '24px',
                    height: '24px',
                }}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m.75 12 3 3m0 0 3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                </svg>
            )}
        </Button>
    );
};

export default ExportButton;
