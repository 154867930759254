import React from 'react';
import NumberDetails from "../components/NumberDetails/NumberDetails";
import UrlTooltip from "../components/UrlTooltip";

import { Link } from 'react-router-dom';
import s from './Holder.module.scss'
import { Checkbox } from '@mui/material';

const LeadItem = React.memo(({
    lead,
    index,
    handleDelete,
    sendLeadNow,
    selected,
    onSelectChange,
}) => {
    const [isSending, setIsSending] = React.useState(false);

    const handleSendNow = async () => {
        setIsSending(true);
        await sendLeadNow(lead.id);
        setIsSending(false);
    };


    const detailsHandler = (partnerName) => {
        const partners = {
            'axela': 'message-warning',
            'cpabandtracker': 'message-info',
            'aivix': 'message-danger',
            'cryp.im': 'message-attantion',
            'trump': 'message-dark'
        };
        return partners[partnerName] || '';
    };

    return (
        <div
            className="table-row"
            style={{
                display: "grid",
                gridTemplateColumns:
                    "45px 45px 45px repeat(3, 100px) 200px 200px 200px 90px 120px",
                alignItems: "center",
                borderBottom: "1px solid #eee",
                padding: "4px 0",
            }}
        >
            {/* Стовпець для чекбокса */}
            <div style={{ textAlign: "center" }}>
                <Checkbox
                    checked={selected}
                    onChange={(e) => onSelectChange(e.target.checked)}
                />
            </div>
            <div onDoubleClick={() => handleDelete(lead.id)} style={{ color: 'red', cursor: 'pointer' }}>✕</div>
            <div>{index + 1}</div>
            <div>{new Date(lead['createdAt']).toLocaleString('uk-UA')}</div>
            <div>{lead['first_name']}</div>
            <div>{lead['last_name']}</div>
            <div style={{
                whiteSpace: "normal",
                overflowWrap: "break-word",
                wordBreak: "break-all"
            }}>{lead['email']}</div>
            <div><NumberDetails phoneNumber={lead['phone']} /></div>
            <div style={{
                maxHeight: 25,
                overflow: 'hidden',
                position: 'relative',
            }}>
                <UrlTooltip url={lead['ref']} />
            </div>
            <div>
                {lead['utm'] && <div style={{ color: '#337ab7', fontSize: '12px' }}>{lead['utm']}</div>}
                <Link to={`/offers/${lead['Link'].OfferId}/add?search=${lead['Link'].name}`} >{lead['Link'].name}</Link>
                {lead['campaign'] && <div style={{ color: '#b73333', fontSize: '12px' }}>{lead['campaign']}</div>}
            </div>
            <div className={`message ${detailsHandler(lead?.Link?.Offer?.Partner.name || '')}`} style={{ margin: 0, overflow: 'hidden' }} title={`${lead?.Link?.Offer?.Partner.name ?? ''}`}>{lead?.Link?.Offer?.Partner.name ?? ''}</div>
            <div>
                <button onClick={handleSendNow} className="btn btn-success" disabled={isSending}>
                    {isSending ? 'Sending...' : 'Send Now'}
                </button>
            </div>
            <div className={s.transferWrap}>
                {lead?.Link?.Holders.map((el, index) => (
                    <div className={`message message-dark`} style={{ marginBottom: '2px' }} key={el.holder_from + el.holder_to + index}>
                        <span>{el.holder_from} - {el.holder_to}</span>
                    </div>
                ))}
            </div>
            <div className={s.transferWrap}>
                {lead?.Link?.Transfers.map((el, index) => (
                    <div className={`message ${detailsHandler(el.transfer_road)}`} style={{ marginBottom: '2px' }} key={el.transfer_from + el.transfer_to + index}>
                        <span>{el.transfer_from} - {el.transfer_to}</span>
                        <span> → {el.transfer_road}</span>
                    </div>
                ))}
            </div>
        </div>
    );
});

export default LeadItem;
