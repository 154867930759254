// src/store/store.js
import { configureStore } from '@reduxjs/toolkit'
import leadsReducer from './leadsSlice'
// ... возможно другие слайсы

export const store = configureStore({
    reducer: {
        leads: leadsReducer,
        // ... другие
    }
});
